@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Poppins", "sans-serif";
  text-decoration: none;
  scroll-behavior: smooth;
  scrollbar-width: .25rem;
}

::-webkit-scrollbar {
  width: .5rem;
  height: 1rem;
}

/* ::-webkit-scrollbar-track {
  background: rgb(17 24 39); 
} */
 
::-webkit-scrollbar-thumb {
  /* background: #888; */
  border-radius: 1rem; 
}

/* ::-webkit-scrollbar-thumb:hover {
  background: #555; 
} */

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  height: 100dvh;
  position: relative;
  scroll-behavior: smooth;
  /* overflow: hidden; */
}
#root {
  height: 100%;
}